import { Component
} from '@angular/core';
import { Router } from '@angular/router';
import {AuthenticationService} from "../../services/authentication/authentication.service";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent {

  constructor(private router: Router, private authenticationService: AuthenticationService) {}

  ngAfterViewInit() {
    this.login();
  }

  login() {
    this.authenticationService.authenticate().then(() => {
      this.router.navigate(['digital-twin-test']);
    }).catch(() => {
      alert('error during logging in');
      throw new Error('error during logging in');
    });
  }
}
