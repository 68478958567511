import {HttpClient, HttpHeaders} from '@angular/common/http';
import {AfterViewInit, Component} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {MatDialog} from '@angular/material/dialog';
import {map} from 'rxjs/operators';
import {AuthenticationService} from '../core/services/authentication/authentication.service';

import {ConfirmPopupComponent} from '../shared/confirm-popup/confirm-popup.component';
import {SharedProduct} from '../shared/models/shareditem/shared-product';
import {SharedItemUpdateDTO} from '../shared/models/shareditem/shared-item-update.dto';
import {MatSnackBar} from "@angular/material/snack-bar";
import {MessageService} from '../shared/services/message.service';
import {HelpersService} from "../shared/services/helpers.service";

@Component({
  selector: 'app-shared-product',
  templateUrl: './shared-product.component.html',
  styleUrls: ['./shared-product.component.css']
})
export class SharedProductComponent {
  dataSource = new MatTableDataSource<SharedProduct>();
  dataLoaded = false;
  displayedColumns: string[] = ['OD', 'productName', 'productNumber', 'changedDate', 'changedByEmail', 'active', 'options'];

  constructor(
    private snackBar: MatSnackBar,
    private http: HttpClient,
    private messageService: MessageService,
    public dialog: MatDialog,
    private authenticationService: AuthenticationService) {
    this.getInformation();

    this.messageService.getMessage().subscribe(message => {
      if (message) {
        this.snackBar.open(message.text, '', {
          duration: 5000,
          verticalPosition: 'top',
          horizontalPosition: 'center',
          panelClass: ['green-snackbar']
        });
      }
    });
  }

  getInformation() {
    this.http.get<any>("/api/v1/sharedproduct").pipe(map(res => res.map((x: SharedProduct) => {
      return {
        id: x.id,
        odName: x.odName,
        materialName: x.productName,
        productNumber: x.productNumber,
        allDataSelected: x.allDataSelected,
        sharingStatus: x.sharingStatus,
        creationDateTime: (x.creationDateTime !== undefined) ? new Date(x.creationDateTime).toLocaleString() : '',
        createdByUserFullName: x.createdByUserFullName,
        createdByUserEmail: x.createdByUserEmail
      };
    }))).subscribe(
      res => {
        this.dataSource.data = res;
        this.dataLoaded = true;
      }
    );
  }

  changeSharingStatus(sharedProductId: string, odName: string, sharingStatus: boolean) {
    let body: SharedItemUpdateDTO = {
      id: sharedProductId,
      sharingStatus: (sharingStatus) ? false : true,
      odName: odName
    }

    this.http.patch<SharedItemUpdateDTO>('/api/v1/sharedproduct', body, this.HttpOptions).subscribe(
      (response) => {
        this.getInformation();
      },
      (error) => {
        if (error.status == 500 || error.status == 405) {
          this.snackBar.open(error.error.error, '', {
            duration: 5000,
            verticalPosition: 'top',
            horizontalPosition: 'center',
            panelClass: ['red-snackbar']
          });
        } else {
          this.snackBar.open(error.error, '', {
            duration: 5000,
            verticalPosition: 'top',
            horizontalPosition: 'center',
            panelClass: ['red-snackbar']
          });
        }
      }
    );
  }

  openConfirmationPopup(sharedProductId: string, odName: string, sharingStatus: boolean) {
    const dialogRef = this.dialog.open(ConfirmPopupComponent, {
      data: {
        dialogTitle: 'Are you sure you want to change the status for the current Shared Product?',
        dialogContent: `
        <h6 class="popup-message-modifyDT">Should you opt to change the status for the Shared Product, the following changes are to be anticipated:</h6>
        <ul>
          <li>
            If there are other Shared Products with the same od name, the active status could change from 'Active' to 'Not active'.
            This will vary depending on each case.
          </ul>`,
        buttonText: 'Change Shared Product status'
      },
      position: { top:'200px' },
      maxWidth: '50vw',
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result != undefined)
        this.changeSharingStatus(sharedProductId, odName, sharingStatus);
    });
  }

  isPermittedAction(action: string) {
    return this.authenticationService.isPermitedActionForUserGroups(action);
  }

  get HttpOptions() {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    }
  }

  protected readonly HelpersService = HelpersService;
}

