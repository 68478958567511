<div class="card-content">
  <div class="card-header">
    <div class="card-title">{{ title }}</div>
    <div>
      <app-nav [breadcrumbList]="breadcrumb"></app-nav>
    </div>
  </div>
  <div>
    <ng-content></ng-content>
  </div>
</div>
