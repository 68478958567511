import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {Router} from "@angular/router";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {MatDialog} from '@angular/material/dialog';
import {AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ConfirmPopupComponent} from '../shared/confirm-popup/confirm-popup.component';
import {SharedItemDTO} from '../shared/models/shareditem/shared-item.dto';
import {MatSnackBar} from "@angular/material/snack-bar";
import {MatStepper} from "@angular/material/stepper";
import {MessageService} from '../shared/services/message.service';
import {AuthenticationService} from '../core/services/authentication/authentication.service';
import {Observable, startWith} from "rxjs";
import {map} from "rxjs/operators";


import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {MatAutocomplete, MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import {MatChipInputEvent} from '@angular/material/chips';
import {MatSelectChange} from "@angular/material/select";


@Component({
  selector: 'app-shared-item-new',
  templateUrl: './shared-item-new.component.html',
  styleUrls: ['./shared-item-new.component.css']
})
export class SharedItemNewComponent implements OnInit {
  formGroup: FormGroup;
  breadcrumb: any

  materialNameControl = new FormControl<string | any>('');

  finalList: any;
  partnerList: any = [];
  odList: any = [];
  partnerNameList: any = [];
  materialList: any = [];
  filteredMaterials: Observable<any[]>;
  emptyMaterialList: any = [];

  existOtherSharedItemRules: boolean = false
  warningInputFieldsRequired: string = "There are required input fields that need to be filled out.";

  separatorKeysCodes: number[] = [ENTER, COMMA];
  @ViewChild('materialInput') materialInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto', {static: false}) matAutocomplete: MatAutocomplete;


  constructor(
    private _formBuilder: FormBuilder,
    private snackBar: MatSnackBar,
    private http: HttpClient,
    private router: Router,
    public dialog: MatDialog,
    public messageService: MessageService,
    private authenticationService: AuthenticationService) {
  }

  ngOnInit() {

    this.formGroup = this._formBuilder.group({
      formArray: this._formBuilder.array([
        this._formBuilder.group({
          useCase: [{value: 'Traceability', disabled: true}, Validators.required],
        }),
        this._formBuilder.group({
          odName: ['', Validators.required]
        }),
        this._formBuilder.group({
          partnerName: ['', Validators.required],
        }),
        this._formBuilder.group({
          selectAllProducts: [true, Validators.required]
        }),
      ]),
      materialNameControl: this.materialNameControl
    });

    this.getInformation();
  }

  addFromDropdown(event: MatAutocompleteSelectedEvent): void {
    const value = event.option.value;
    if (value) {
      this.emptyMaterialList.push(value);
      this.materialList = this.materialList.filter(m => m._id.materialName !== value._id.materialName);
    }
    this.materialInput.nativeElement.value = '';
    this.materialNameControl.setValue(null);
  }

  addManual(event: MatChipInputEvent): void {
    if (!this.matAutocomplete.isOpen) {
      const value = event.value.trim();
      const onlyNumbersRegex = /^\d+$/;

      if (value) {
        if (!onlyNumbersRegex.test(value)) {
          this.snackBar.open('Material number must contain only numbers.', '', {
            duration: 3000,
            verticalPosition: 'top',
            horizontalPosition: 'center',
            panelClass: ['red-snackbar']
          });
          return;
        }

        const paddedValue = value.padStart(18, '0');
        const isDuplicate = this.emptyMaterialList.some(item => item._id.materialNumber === paddedValue);
        if (!isDuplicate) {
          const material = {
            _id: {
              materialName: '',
              materialNumber: paddedValue
            }
          };
          this.emptyMaterialList.push(material);
          this.materialList = this.materialList.filter(m => m._id.materialNumber !== paddedValue);
        }
      }

      this.materialInput.nativeElement.value = '';
      this.materialNameControl.setValue(null);
    }
  }

  remove(material: any): void {
    const index = this.emptyMaterialList.indexOf(material);
    if (index >= 0) {
      this.emptyMaterialList.splice(index, 1);
      this.materialList.push(material);
      this.filteredMaterials = this.materialNameControl.valueChanges.pipe(
        startWith(null),
        map((material: string | null) => {
          return material ? this._filter(material) : this.materialList.slice();
        }),
      );
    }
  }

  submit() {
    if (!this.selectAllProducts && this.emptyMaterialList.length === 0) {
      this.activateAlert('The material list is empty. Please add at least one material before submitting.');
      return;
    }

    this.existSharedItemByOdName();
    this.openConfirmationPopup();
  }

  goBack(stepper: MatStepper) {
    const currentStep = stepper.selectedIndex;
    if (currentStep === 2) {
      this.partnerNameList = [];
      this.formGroup.get('formArray').get([1]).get('odName').reset();
    }
    if (currentStep === 3) {
      this.formGroup.get('formArray').get([3]).get('selectAllProducts').setValue(true);
      this.emptyMaterialList = [];
    }
    stepper.previous();
  }

  goForward(stepper: MatStepper) {
    const currentStep = stepper.selectedIndex;
    if (currentStep === 0) {
      this.getPartnerList();
    }
    stepper.next();
  }

  getInformation() {
    this.breadcrumb = [
      {title: 'Shared Products', href: `/shared-products`},
      {title: 'Create Shared Items', href: ''}
    ];
  }

  filterODs(partnerOD: modelA) {
    this.partnerNameList = [];
    this.partnerList.filter((item: any) => {
      if (item._id.od === partnerOD._id.od) {
        this.partnerNameList.push(item);
      }
    });
  }

  getPartnerList() {
    this.http.get<any>('/api/v1/shareditem/partnerList', this.HttpOptions).subscribe(
      (response: any) => {
        this.partnerList = response;
        this.odList = Array.from(this.partnerList.reduce((m, t) => m.set(t._id.od, t), new Map()).values());
      })
  }

  selectPartner($event: MatSelectChange) {
    this.finalList = $event.value;
    this.getMaterialList($event.value._id);

    this.filteredMaterials = this.materialNameControl.valueChanges.pipe(
      startWith(null),
      map((material: string | null) => {
        return material ? this._filter(material) : this.materialList.slice();
      }),
    );
  }

  getMaterialList($event: any) {
    this.http.get<any>('/api/v1/shareditem/materialList/' + $event.partnerID + "/" + $event.od, this.HttpOptions).subscribe(
      (response: any) => {
        this.materialList = response;
      })
  }

  displayFn(material: any): string {
    return material && material._id.materialName ? material._id.materialName : '';
  }

  private _filter(value: any): any[] {
    let filterValue: string;
    if (typeof value === 'string') {
      filterValue = value.toLowerCase();
    } else if (value && value._id && typeof value._id.materialName === 'string') {
      filterValue = value._id.materialName.toLowerCase();
    } else {
      return [];
    }
    return this.materialList.filter(material => material._id.materialName.toLowerCase().includes(filterValue));
  }

  existSharedItemByOdName() {
    this.http.get<boolean>(`/api/v1/shareditem/exitsbyodname/${this.finalList._id.od}`, this.HttpOptions).subscribe(
      (response) => {
        this.existOtherSharedItemRules = response;
      },
      (error) => {
        if (error.status == 500 || error.status == 405) {
          this.activateAlert(error.error.error);
        } else {
          this.activateAlert(error.error);
        }
      }
    )
  }

  openConfirmationPopup() {
    this.existSharedItemByOdName();
    if (this.existOtherSharedItemRules) {
      const dialogRef = this.dialog.open(ConfirmPopupComponent, {
        data: {
          dialogTitle: 'Create Shared Items',
          dialogContent: `
          <h6 class="popup-message-modifyDT">Should you opt to add new Shared Items, the following changes are to be anticipated:</h6>
          <ul>
            <li>
              If there are other Shared Items with the same od name as the new ones, the active status could change from 'Active' to 'Not active'.
              This will vary depending on each case.
            </ul>`,
          buttonText: 'Create Shared Items'
        },
        position: {top: '200px'},
        maxWidth: '50vw',
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result != undefined)
          this.createSharedItems();
      });
    } else
      this.createSharedItems();
  }

  createSharedItems() {
    if (this.formGroup.valid) {
      const productNumberList = this.emptyMaterialList.map(material => ({
        materialNumber: material._id.materialNumber,
        materialName: material._id.materialName
      }));
      const formData: SharedItemDTO = {
        useCase: 'Traceability',
        odName: this.finalList._id.od,
        customerNumberList: [this.finalList._id.partnerID],
        productNumberList: productNumberList,
        selectAllProducts: this.selectAllProducts,
        customerName: this.finalList._id.partnerName
      };
      this.http.post<SharedItemDTO>("/api/v1/shareditem", formData, this.HttpOptions).subscribe(
        (response) => {
          this.messageService.sendMessage('Shared Items have been created successfully!');
          this.router.navigate(["/shared-products"]);
        },
        (error) => {
          if (error.status == 500 || error.status == 405) {
            this.activateAlert(error.error.error);
          } else {
            this.activateAlert(error.error);
          }
        }
      )
    } else {
      this.activateAlert(this.warningInputFieldsRequired);
    }
  }

  get formArray(): AbstractControl {
    return this.formGroup.get('formArray');
  }

  get selectAllProducts(): boolean {
    const formArray = this.formGroup.get('formArray') as FormArray;
    const productNumberListGroup = formArray.controls[3] as FormGroup;

    return productNumberListGroup.get('selectAllProducts').value;
  }

  get HttpOptions() {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    }
  }

  activateAlert(message: string) {
    this.snackBar.open(message, '', {
      duration: 5000,
      verticalPosition: 'top',
      horizontalPosition: 'center',
      panelClass: ['red-snackbar']
    });
  }

}

export interface modelA {
  _id: modelB;
}

export interface modelB {
  partnerName: string;
  partnerID: string;
  od: string;
}
