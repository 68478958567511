<div class="container">
  <h1 class="title">{{text}}</h1>
  <div class="content">
    <mat-card class="card">
      <mat-card-content>
        <ng-content></ng-content>
      </mat-card-content>
    </mat-card>
  </div>
  <div class="logo-container">
    <img alt="BASF Logo" class="basf-logo"
      src="https://www.basf.com/etc.clientlibs/basf/wcm/components/static/logo/clientlib/resources/logo.v1.svg">
  </div>
</div>