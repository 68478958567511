<app-card-layout [title]="digitalTwinPageTitle" [breadcrumb]="breadcrumb">
  <form [formGroup]='form' (ngSubmit)="createDigitalTwin()">

    <mat-form-field appearance="outline" class="width-48 m-r-4" *ngIf="isRegistered">
      <mat-label>CX Identification</mat-label>
      <input formControlName="cxIdentification" matInput>
    </mat-form-field>

    <mat-form-field appearance="outline" class="width-48" *ngIf="isRegistered">
      <mat-label>CX Global Asset Id</mat-label>
      <input formControlName="cxGlobalAssetId" matInput>
    </mat-form-field>

    <mat-form-field appearance="outline" class="width-48 m-r-4">
      <mat-label>Business Partner Number</mat-label>
      <input formControlName="bpn" matInput>
    </mat-form-field>

    <mat-form-field appearance="outline" class="width-48">
      <mat-label>BASF Material Number</mat-label>
      <input formControlName="materialNumberBASF" matInput>
    </mat-form-field>

    <mat-form-field appearance="outline" class="width-48 m-r-4" floatLabel="always">
      <mat-select formControlName="digitalTwinType" placeholder="Digital Twin Type">
        <mat-option [value]="DigitalTwinType.PartInstance">Part Instance</mat-option>
        <mat-option [value]="DigitalTwinType.PartType">Part Type</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="outline" class="width-48">
      <mat-label>Batch Number</mat-label>
      <input formControlName="batchNumber" matInput>
    </mat-form-field>

    <div class="text-wrapper">
      <div class="text-wrapper-title">
        <div>
          Customers
        </div>
      </div>

      <button type="button" mat-raised-button color="primary" (click)="onClickAddCustomer()" *ngIf="!production"
        style="margin-bottom: 25px;">
        <mat-icon>add</mat-icon>
        Add customer
      </button>

      <div *ngFor="let customer of customerList.controls; let i = index" style="margin-bottom: 5px;" class="align-items-center">
        <mat-form-field appearance="outline" class="width-20 m-r-2">
          <mat-label>Customer Material No.</mat-label>
          <input [formControl]="getCustomerControl(i, 'customerMaterialNumber')" matInput>
        </mat-form-field>

        <mat-form-field appearance="outline" class="width-20 m-r-2">
          <mat-label>Customer BPN</mat-label>
          <input [formControl]="getCustomerControl(i, 'customerBpn')" matInput>
        </mat-form-field>

        <mat-form-field appearance="outline" class="width-20">
          <mat-label>Customer Name</mat-label>
          <input [formControl]="getCustomerControl(i, 'customerName')" matInput>
        </mat-form-field>

        <button mat-icon-button color="primary" (click)="onClickRemoveCustomer(i)" matTooltip="Delete customer" class="margin-bottom-20" *ngIf="!production">
          <mat-icon>delete</mat-icon>
        </button>
      </div>
    </div>

    <div *ngIf="!production">
      <button mat-raised-button color="primary" *ngIf="!savingFromScratch; else newRecord"
        (click)="openConfirmationPopup()">
        Modify Digital Twin
      </button>
      <ng-template #newRecord>
        <button mat-raised-button color="primary" type="submit">Create Digital Twin</button>
      </ng-template>
    </div>

  </form>

  <app-progress-spinner [dataLoaded]="dataLoaded"></app-progress-spinner>
</app-card-layout>

<div *ngIf="digitalTwinAspectModelsDTO !== null && !production">
  <br>
  <div style="text-align: initial;">Aspect models associated:</div>
  <br>

  <mat-expansion-panel *ngIf="digitalTwinAspectModelsDTO.batch !== undefined" (opened)="panelOpenState1 = true"
    (closed)="panelOpenState1 = false">
    <mat-expansion-panel-header>
      <mat-panel-title>
        Batch
      </mat-panel-title>
    </mat-expansion-panel-header>
    <app-batch-new [idFromOutsideTheComponent]="digitalTwinAspectModelsDTO.batch.id"></app-batch-new>
  </mat-expansion-panel>

  <mat-expansion-panel *ngIf="digitalTwinAspectModelsDTO.partTypeInformation !== undefined"
    (opened)="panelOpenState3 = true" (closed)="panelOpenState3 = false" style="margin-top: 10px;">
    <mat-expansion-panel-header>
      <mat-panel-title>
        PartTypeInformation
      </mat-panel-title>
    </mat-expansion-panel-header>
    <app-part-type-information-new
      [idFromOutsideTheComponent]="digitalTwinAspectModelsDTO.partTypeInformation.id"></app-part-type-information-new>
  </mat-expansion-panel>
</div>
