import {HttpClient, HttpHeaders} from '@angular/common/http';
import {AfterViewInit, Component} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {MatDialog} from '@angular/material/dialog';
import {map} from 'rxjs/operators';
import {AuthenticationService} from '../core/services/authentication/authentication.service';
import {MatSnackBar} from "@angular/material/snack-bar";

import {ConfirmPopupComponent} from '../shared/confirm-popup/confirm-popup.component';
import {SharedItemUpdateDTO} from '../shared/models/shareditem/shared-item-update.dto';
import {SharedCustomer} from '../shared/models/shareditem/shared-customer';


@Component({
  selector: 'app-shared-customer',
  templateUrl: './shared-customer.component.html',
  styleUrls: ['./shared-customer.component.css']
})
export class SharedCustomerComponent {
  dataSource = new MatTableDataSource<SharedCustomer>();
  dataLoaded = false;
  displayedColumns: string[] = ['OD', 'customerName', 'changedDate', 'changedByEmail', 'active', 'options'];

  constructor(
    private snackBar: MatSnackBar,
    private http: HttpClient,
    public dialog: MatDialog,
    private authenticationService: AuthenticationService) {
    this.getInformation();
  }

  getInformation() {
    this.http.get<any>("/api/v1/sharedcustomer").pipe(map(res => res.map((x: SharedCustomer) => ({
      id: x.id,
      odName: x.odName,
      customerName: x.customerName,
      allDataSelected: x.allDataSelected,
      sharingStatus: x.sharingStatus,
      creationDateTime: (x.creationDateTime !== undefined) ? new Date(x.creationDateTime).toLocaleString() : '',
      createdByUserFullName: x.createdByUserFullName,
      createdByUserEmail: x.createdByUserEmail
    })))).subscribe(
      res => {
        this.dataSource.data = res;
        this.dataLoaded = true;
      }
    );
  }

  changeSharingStatus(sharedCustomerId: string, odName: string, sharingStatus: boolean) {
    let body: SharedItemUpdateDTO = {
      id: sharedCustomerId,
      sharingStatus: !(sharingStatus),
      odName: odName
    }

    this.http.patch<SharedItemUpdateDTO>('/api/v1/sharedcustomer', body, this.HttpOptions).subscribe(
      () => {
        this.getInformation();
      },
      (error) => {
        if (error.status == 500 || error.status == 405) {
          this.snackBar.open(error.error.error, '', {
            duration: 5000,
            verticalPosition: 'top',
            horizontalPosition: 'center',
            panelClass: ['red-snackbar']
          });
        } else {
          this.snackBar.open(error.error, '', {
            duration: 5000,
            verticalPosition: 'top',
            horizontalPosition: 'center',
            panelClass: ['red-snackbar']
          });
        }
      }
    );
  }

  openConfirmationPopup(sharedCustomerId: string, odName: string, sharingStatus: boolean) {
    const dialogRef = this.dialog.open(ConfirmPopupComponent, {
      data: {
        dialogTitle: 'Are you sure you want to change the status for the current Shared Customer?',
        dialogContent: `
        <h6 class="popup-message-modifyDT">Should you opt to change the status for the Shared Customer, the following changes are to be anticipated:</h6>
        <ul>
          <li>
            If there are other Shared Customers with the same od name, the active status could change from 'Active' to 'Not active'.
            This will vary depending on each case.
          </ul>`,
        buttonText: 'Change Shared Customer status'
      },
      position: { top: '200px' },
      maxWidth: '50vw',
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result != undefined)
        this.changeSharingStatus(sharedCustomerId, odName, sharingStatus);
    });

  }

  isPermittedAction(action: string) {
    return this.authenticationService.isPermitedActionForUserGroups(action);
  }

  get HttpOptions() {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    }
  }

}
