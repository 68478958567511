import { Component, Input } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs';

interface MenuItem {
  name: string;
  children: { name: string; link: string }[];
}

@Component({
  selector: 'app-top-menu',
  templateUrl: './top-menu.component.html',
  styleUrl: './top-menu.component.css'
})
export class TopMenuComponent {
  @Input() isUserLogged: boolean;

  menuItems: MenuItem[] = [
    {
      name: 'DT Catalog (Automated)',
      children: [
        { name: 'Digital Twins', link: '/digital-twin' },
        { name: 'Add shared Items', link: '/shared-items-new' },
        { name: 'Shared Products', link: '/shared-products' },
        { name: 'Shared Customers', link: '/shared-customers' }
      ]
    },
    {
      name: 'DT Creation (Manual)',
      children: [
        { name: 'Digital Twins Test', link: '/digital-twin-test' },
        { name: 'Batch Aspect Models', link: '/batch' },
        { name: 'Part Type Information Aspect Models', link: '/part-type-information' }
      ]
    },
    {
      name: 'API',
      children: [
        { name: 'Lookup Data Model', link: '/lookup-data-model' }
      ]
    },
    {
      name: 'Notifications',
      children: [
        { name: 'Quality', link: '/quality-notifications' },
        { name: 'Unique ID Push', link: '/uniqueidpush-notifications' }
      ]
    },
    {
      name: 'DTR',
      children: [
        { name: 'Catalog', link: '/dtr-catalog' },
      ]
    },
    {
      name: 'EDC',
      children: [
        { name: 'Catalog', link: '/edc-catalog' },
        { name: 'Asset Data', link: '/edc-asset-data' },
        { name: 'Contract negotiations', link: '/edc-contracts' },
        { name: 'Transfer Processes', link: '/edc-transfers' }
      ]
    }
  ];

  currentUrl: string = '';

  constructor(private router: Router) {

  }

  ngOnInit(): void {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      this.currentUrl = event.urlAfterRedirects;
    });
  }

  isActive(item: MenuItem): boolean {
    return item.children.some(child => this.currentUrl === child.link);
  }
}
