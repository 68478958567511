import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { LOCATION } from '@ng-web-apis/common';
import { Observable, of, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { FederationServiceConfiguration } from '../../models/federation-service-config';
import { Group } from '../../models/group';
import { TokenInformation } from '../../models/token-information';
import { FSUser, mapUser } from '../../models/user';
import { DEFAULT_USER_MAPPING } from '../../models/user-mapping';
import { GROUPS_PERMITTED_ROUTES } from './groups-permitted-routes';
import { GROUPS_PERMITTED_ACTIONS } from './groups-permitted-actions';
import { Router } from '@angular/router';

interface IndexedGroup {
  name: string;
  index: number;
}

/** The main Titanium service responsible for the whole process of authentication. */
@Injectable()
export class AuthenticationService {

  /** @ignore */
  private readonly FED_SERVICE_CONFIG_ENDPOINT = 'oauth/nam/.well-known/openid-configuration';
  /** @ignore */
  private readonly FED_SERVICE_LOGOUT_ENDPOINT = 'app/logout';
  /** @ignore */
  private _token = TokenInformation.initalize();
  /** @ignore */
  private _user: FSUser;
  /** @ignore */
  private helper: JwtHelperService = new JwtHelperService();
  /** @ignore */
  private isUserExternal = false;

  /** Access token */
  get token(): TokenInformation {
    return this._token;
  }

  /** Logged in user */
  get user(): FSUser {
    return this._user;
  }

  /** @ignore */
  constructor(
    private httpClient: HttpClient,
    @Inject(LOCATION) private readonly location: any,
    private router: Router
  ) {
    this.location = location as Location;
  }

  /**
   * Gets the access token from the local storage. If it is not available returns null.
   */
  getAuthToken(): string {
    return this.token
      ? this.token.accessToken
      : null;
  }

  getOrgCode(): string {
    return this.user.orgCode;
  }
  /**
   * Checks whether the user is authenticated.
   * If the federationService flag is true it will check if the access token is in the local storage and if it is not expired.
   * If the federationService flag is false it will return true.
   */
  isAuthenticated(): boolean {
    if (environment.federationService) {
      try {
        return this.token.accessToken && !this.isTokenExpired();
      } catch {
        return false;
      }
    } else {
      return true;
    }
  }

  /**
   * Checks whether the user is in the all specified groups
   * @param groups a list of user groups
   */
  isUserInGroups(groups: string[]): boolean {
    const matchedGroups = this.getMatchedUserGroups(groups);
    return matchedGroups.length === groups.length;
  }

  /**
   * Checks whether the user is in at least in one of the specified groups
   * @param groups a list of user groups
   */
  isUserInAtLeastInOneOfGroups(groups: string[]): boolean {
    const matchedGroups = this.getMatchedUserGroups(groups);
    return matchedGroups.length > 0;
  }

  isPermitedRouteForUserGroups(route: string): boolean {
    if (!this.user) {
      return false;
    }
    const userGroups = this.user.groups;
    return userGroups.some(group => GROUPS_PERMITTED_ROUTES[group]?.includes(route));
  }

  isPermitedActionForUserGroups(action: string): boolean {
    const userGroups = this.user.groups;
    return userGroups.some(group => GROUPS_PERMITTED_ACTIONS[group]?.includes(action));
  }

  /**
   * Checks whether the user is in a group which is hierarchicaly lower than the one mentioned in the group param
   * @param group concrete group which should be checked
   * @param groupEnum Enum type which is used for defining the group. Make sure to have the enum properties sorted hierarchicaly.
   */
  hasRightsHigherThan(group: string, groupEnum?: any): boolean {
    const enumOfGroups = groupEnum ?? Group;
    if (!enumOfGroups) return false;
    const searchedGroup: IndexedGroup = {
      name: group,
      index: this.indexOfGroup(enumOfGroups, group)
    };
    return this.getMaxPrivilege(enumOfGroups) > searchedGroup.index;
  }

  /**
   * Checks whether the user is in a group which is hierarchicaly lower or equal than the one mentioned in the group param.
   * @param group concrete group which should be checked
   * @param groupEnum Enum type which is used for defining the group. Make sure to have the enum properties sorted hierarchicaly.
   */
  hasRightsHigherThanOrEqual(group: string, groupEnum?: any): boolean {
    const enumOfGroups = groupEnum ?? Group;
    if (!enumOfGroups) return false;
    const searchedGroup: IndexedGroup = {
      name: group,
      index: this.indexOfGroup(enumOfGroups, group)
    };
    const usersMaxPrivileges: number = this.getMaxPrivilege(enumOfGroups);
    return usersMaxPrivileges >= searchedGroup.index;
  }

  /**
   * Checks if the user has Admin or higher rights.
   * @param groupEnum if the enum is not set via the titanium congig you can pass it also here.
   */
  isAdmin(groupEnum?: any): boolean {
    const enumOfGroups = groupEnum ?? Group;
    if (!enumOfGroups) return false;
    return this.hasRightsHigherThanOrEqual(enumOfGroups.ADMIN, enumOfGroups);
  }

  /** Assignes a numeric value to each group based on its position in the Enum Group class and gets the max privilege/number */
  private getMaxPrivilege(groupEnum): number {
    const matchedGroups = this.getMatchedUserGroups(Object.values(groupEnum));

    const indexedMatchedGroups: IndexedGroup[] = matchedGroups.map(matchedGroup => {
      return {
        name: matchedGroup,
        index: this.indexOfGroup(groupEnum, matchedGroup)
      };
    });
    let maxPrivilege = -1;
    indexedMatchedGroups.forEach(indexedMatchedGroup => {
      if (indexedMatchedGroup.index > maxPrivilege) {
        maxPrivilege = indexedMatchedGroup.index;
      }
    });

    return maxPrivilege;
  }

  /**
   * Checks the value of the federationService flag in the Titanium config
   */
  isFederationServiceTurnedOn(): boolean {
    return environment.federationService;
  }

  /**
   * Main function which authenticates the user.
   * @param isUserExternal if true, it will redirect the user to an external login page.
   */
  authenticate(isUserExternal?: boolean): Promise<void> {
    this.isUserExternal = !!isUserExternal;
    return new Promise(async (resolve, reject) => {
      if (this.isAuthenticated()) {
        this.createUser();
        resolve();
      } else {
        const code: string = this.getQueryParameterByName('code');
        if (code) {
          try {
            const token = await this.getToken(code);
            this.saveToken(token);
            resolve();
          } catch (error) {
            // TODO show proper error message
            console.error('failed to obtain the token', error);
            reject(error);

            this.router.navigate(['not-authorized']);
          }
        } else {
          try {
            if (this.location.hostname === 'localhost') {
              this.localHostLogIn();
              resolve();
            } else {
              const fedServiceConfig = await this.getFederationServiceConfiguration();
              this.getAuthCodeRedirect(fedServiceConfig);
            }
          } catch (error) {
            // TODO show proper error message
            console.error('failed to obtain the configuration from the federation service', error);
            reject(error);
          }
        }
      }
    });
  }

  /**
   * Finds out if the token is expired.
   * @param offsetSeconds use this when you want to consider the token as expired even earlier.
   */
  isTokenExpired(offsetSeconds: number = 1800): boolean {
    try {
      if (!this.token.accessToken) {
        throw new Error(('accessToken is null'));
      }
      return this.helper.isTokenExpired(this.token.accessToken, offsetSeconds);
    } catch (error) {
      console.error('Token is broken. Clearing the local storage', error);
      this.token.clearLocalStorage();
      throw new Error(error);
    }
  }

  /**
   * Extracts the URL query parameter value by name.
   * @param name URL query parameter name
   */
  getQueryParameterByName(name: string): string {
    const url = this.location.href;
    name = name.replace(/[[]]/g, '\$&');
    const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)');
    const results = regex.exec(url);
    if (!results) {
      return null;
    }
    if (!results[2]) {
      return '';
    }
    return decodeURIComponent(results[2].replace(/\+/g, ''));
  }

  /**
   * Destroys the user object and clears the local storage.
   */
  logout(): Observable<object> {
    /*This is a temporal solution since the /refreshToken does not work*/
    this._user = null;
    this._token.clearLocalStorage();

    const isLocalHost = this.location.hostname === 'localhost';
  
    const revokeToken$ = isLocalHost
      ? of({}) // skip the revoke token call in localhost
      : this.httpClient.post(
        environment.backendUrl.replace('v1/', '') + 'revokeToken',
        { refreshToken: this._token.accessToken }
      );

    return revokeToken$.pipe(
      tap(() => {
        if (!isLocalHost) {
          this.destroyFedServiceCookies();
        }
        this._user = null;
        this._token.clearLocalStorage();
      }),
      catchError((error) => throwError(error))
    );
  }

  /**
   * Creates a user object from the token.
   */
  createUser(): void {
    try {
      const decodedToken = this.helper.decodeToken(this.token.accessToken);
      if (!decodedToken) {
        return;
      }

      this._user = mapUser(decodedToken, DEFAULT_USER_MAPPING);
      this._user.groups = this.parseGroups(decodedToken.CFDSGroupMembership);
    } catch (error) {
      console.error('User cannot be created from the token. Try to log in again.', error);
      throw new Error(error);
    }
  }

  /**
   * Sends a request to refresh the token.
   */
  refreshToken(): Observable<any> {
    return this.httpClient
      .post<any>(
        environment.backendUrl.replace('v1/', '') + 'refreshToken',
        { refreshToken: this.token.refreshToken }
      ).pipe(
        tap(token => {
          this.saveToken(token);
        })
      );
  }

  /** @ignore */
  private saveToken(token: any) {
    this._token = new TokenInformation(
      token.access_token || token.accessToken,
      token.token_type || token.tokenType,
      token.expires_in || token.expiresIn,
      token.refresh_token || token.refreshToken,
      token.id_token || token.idToken
    );
    this.createUser();
  }

  /** @ignore */
  private getAuthCodeRedirect(fedServiceConfig: FederationServiceConfiguration, redirectUrl?: string): void {

    const federationServiceAuthorizationUrl: string = fedServiceConfig.authorization_endpoint;
    let params: HttpParams = new HttpParams()
      .set('client_id', environment.clientId)
      .set('redirect_uri', environment.redirectUri)
      .set('scope', environment.scope)
      .set('resourceServer', environment.resourceServer)
      .set('response_type', environment.responseType);

    if (this.isUserExternal) {
      params = params.set('acr_values', 'basf/username/password/otp/nrl');
    }

    this.location.href = federationServiceAuthorizationUrl + '?' + params.toString();
  }

  /** @ignore */
  private localHostLogIn() {
    const customAccessToken = prompt('enter the ACCESS token you received in a deployed app');
    if (customAccessToken) {
      this.token.accessToken = customAccessToken;
      this.createUser();
    }
  }

  /** @ignore */
  private async getToken(code: string): Promise<any> {
    const url = environment.backendUrl.replace('v1/', '') + 'token';
    return this.httpClient
      .post<any>(
        url,
        { code }
      ).toPromise();
  }

  /** @ignore */
  private async getFederationServiceConfiguration(): Promise<FederationServiceConfiguration> {
    return this.httpClient
      .get<FederationServiceConfiguration>(
        environment.federationServiceUrl + this.FED_SERVICE_CONFIG_ENDPOINT
      ).toPromise();
  }

  /** @ignore */
  private parseGroups(rawGroups: string | string[]): string[] {
    let parsedGroups: string[] = [];
    if (rawGroups && Array.isArray(rawGroups)) {
      parsedGroups = rawGroups
        .map(rawGroup => this.parseGroup(rawGroup))
        .filter(parsedGroup => !!parsedGroup);
    } else if (typeof rawGroups === 'string') {
      parsedGroups.push(this.parseGroup(rawGroups));
    }
    return parsedGroups;
  }

  /** @ignore */
  private parseGroup(rawGroup: string): string {
    const regex = /cn=[\w]*/gm;
    const matches = regex.exec(rawGroup);
    if (matches && matches.length === 1) {
      return matches[0].substring(3); // removing "cn="
    }
    return null;
  }


  /**
   * It takes an array of groups and returns an array of groups that the user is a member of
   * @param {string[]} groups - string[] - An array of groups that the user must be a member of to be
   * able to see the element.
   * @returns An array of strings that are the matched groups.
   */
  private getMatchedUserGroups(groups: string[]): string[] {
    let matchedGroups = [];
    if (!this._user) {
      return matchedGroups;
    }
    groups.forEach(group => {
      matchedGroups = matchedGroups.concat(
        this.user.groups.filter(userGroup => userGroup === group)
      );
    });
    return matchedGroups;
  }


  /**
   * It takes an enum and a value from that enum and returns the index of that value in the enum
   * @param {any} groupEnum - The enum that you want to use to get the index of the group.
   * @param group - the group you want to get the index of
   * @returns The index of the group in the enum.
   */
  private indexOfGroup(groupEnum: any, group): number {
    const keys = Object.keys(groupEnum);
    return keys.indexOf(this.getEnumKeyByEnumValue(groupEnum, group));
  }


  /**
   * It takes an enum and an enum value and returns the key of the enum value
   * @param {any} groupEnum - The enum you want to get the key for
   * @param enumValue - The value of the enum you want to get the key for.
   * @returns The key of the enum value.
   */
  private getEnumKeyByEnumValue(groupEnum: any, enumValue) {
    const keys = Object.keys(groupEnum).filter(x => groupEnum[x] === enumValue);
    return keys.length > 0 ? keys[0] : null;
  }



  /**
   * A bit hacky solution, but our FS provider didn't provide any option to redirect back to our page.
   */
  private destroyFedServiceCookies() {
    if (!environment.federationServiceUrl) {
      throw new Error('Cannot destroy the cookies because of missing "federationServiceUrl" in the titanium config.');
    }
    const win = window.open(
      environment.federationServiceUrl + this.FED_SERVICE_LOGOUT_ENDPOINT,
      '_blank',
      'toolbar=no,status=no,menubar=no,scrollbars=no,resizable=no,visible=none,width=500,height=500,'
    );
    setTimeout(() => win.close(), 3000);
  }

  setAccessToken(accessToken: string): void {
    this._token.accessToken = accessToken;
  }

}
