<app-card-layout [title]="'Shared Customers'">
  <app-table [dataSource]="dataSource" [dataLoaded]="dataLoaded">

    <table mat-table [dataSource]="dataSource" matSort>

      <ng-container matColumnDef="OD">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Operating Division"> Operating Division </th>
        <td mat-cell *matCellDef="let element"> {{ element.odName }} </td>
      </ng-container>

      <ng-container matColumnDef="customerName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by customer name"> Customer Name </th>
        <td mat-cell *matCellDef="let element"> {{ (element.allDataSelected) ? 'All customers' : element.customerName }} </td>
      </ng-container>

      <ng-container matColumnDef="changedDate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by created by name"> Created Date </th>
        <td mat-cell *matCellDef="let element"> {{ element.creationDateTime }} </td>
      </ng-container>

      <ng-container matColumnDef="changedByEmail">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by created by email"> Created By (Email)</th>
        <td mat-cell *matCellDef="let element"> {{ element.createdByUserEmail }} </td>
      </ng-container>

      <ng-container matColumnDef="active">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by active"> Active </th>
        <td mat-cell *matCellDef="let element">
          <div class="{{'isRegistered-'+element.sharingStatus}}">
            {{ element.sharingStatus }}
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="options">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element">
          <div *ngIf="isPermittedAction('activate-deactivate-shared-item')">
            <button mat-icon-button (click)="openConfirmationPopup(element.id, element.odName, element.sharingStatus)"
              *ngIf="!element.sharingStatus; else deactivateButton" matTooltip="Activate">
              <mat-icon color="primary">play_circle_filled</mat-icon>
            </button>
            <ng-template #deactivateButton>
              <button mat-icon-button (click)="openConfirmationPopup(element.id, element.odName, element.sharingStatus)"
                matTooltip="Deactivate">
                <mat-icon color="primary">pause_circle_filled</mat-icon>
              </button>
            </ng-template>
          </div>

        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

  </app-table>
</app-card-layout>
