import { NavigationEnd, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from './core/services/authentication/authentication.service';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit{

  shouldUseFullLayout = true;
  isUserLogged: boolean;

  constructor(private router: Router, private authenticationService: AuthenticationService) {
  }

  ngOnInit(): void {
    this.router.events.pipe(
      filter((event): event is NavigationEnd => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      this.isUserLogged = this.authenticationService.isAuthenticated();
      const blankLayoutRoutes = ['/login', '/logout', '/not-authorized'];
      this.shouldUseFullLayout = !blankLayoutRoutes.includes(event.urlAfterRedirects);
    }); 
  }

  isPermittedRoute(route: string) {
    return this.authenticationService.isPermitedRouteForUserGroups(route);
  }
}
