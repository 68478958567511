<app-card-layout [title]="'Digital Twins'" [breadcrumb]="breadcrumb">
  <div class="vertical-align-center">
    <div class="digital-twin-filter-box">
      <mat-icon color="primary" class="filter-icon">filter_alt
      </mat-icon>
      <mat-form-field appearance="outline">
        <mat-label>Search BASF Material Number</mat-label>
        <input matInput [(ngModel)]="searchTerm" (input)="applySearch()">
      </mat-form-field>
      <select-component
        [states]="[{displayValue: 'All', value: 'All'}, {displayValue: 'Registered', value: 'true'}, {displayValue: 'Unregistered', value: 'false'}]"
        [(state)]="toggleState" (stateChange)="onStateChange($event)" label="Registration Status" />
      <select-component
        [states]="[{displayValue: 'All', value: 'All'}, {displayValue: 'PartType', value: 'PartType'}, {displayValue: 'PartInstance', value: 'PartInstance'}]"
        [(state)]="filterType" (stateChange)="onTypeChange($event)" label="Digital Twin Type" />
    </div>
    <div class="margin-left-auto">
      <button mat-raised-button color="primary" routerLink="/digital-twin-test/new"><mat-icon>add</mat-icon>Create Digital
        Twin</button>
    </div>
  </div>


  <div class="card-list">
    <div *ngFor="let item of pagedItems" class="card-item">
      <div class="card-header" (mouseenter)="tooltip.show()" (mouseleave)="tooltip.hide()">
        <div class="card-title">
          {{ HelpersService.getTrimmedMaterialNumber(item.materialNumberBASF) }}
        </div>

        <div class="margin-left-auto">
          <div class="{{'isRegistered-'+item.isRegistered}}">{{ item.isRegistered ? 'Registered' :
            'Unregistered' }}</div>
        </div>
        <app-custom-tooltip #tooltip [item]="item" tooltipType="digitalTwin"></app-custom-tooltip>
      </div>

      <div class="card-content">
        <p *ngIf="item.digitalTwinType == 'PartInstance'">Batch No.: {{ item.batchNumber }}</p>
        <p>Customer Material No.: {{ HelpersService.getTrimmedMaterialNumber(item.customerNumberList) }}</p>
      </div>

      <div class="card-footer">
        <div class="margin-left-auto">
          <button mat-icon-button [routerLink]="['/digital-twin-test/new/', item.id]" matTooltip="View or Modify">
            <mat-icon color="primary">visibility</mat-icon>
          </button>

          <button mat-icon-button (click)="getComponentHistory(item.id)" matTooltip="History">
            <mat-icon color="primary">history</mat-icon>
          </button>

          <button mat-icon-button (click)="registerDigitalTwin(item.id)"
              *ngIf="!item.isRegistered; else unregisterButton" matTooltip="Register">
              <mat-icon class="icon-register">cloud_upload</mat-icon>
            </button>

            <ng-template #unregisterButton>
              <button mat-icon-button (click)="unregisterConfirmationPopup(item.id)" matTooltip="Unregister">
                <mat-icon class="icon-unregister">cloud_download</mat-icon>
              </button>
            </ng-template>

          <button mat-icon-button [matMenuTriggerFor]="mainmenu" matTooltip="Menu">
            <mat-icon color="primary">more_vert</mat-icon>
          </button>
          <mat-menu #mainmenu="matMenu">
            <button *ngIf="item.isRegistered" mat-menu-item (click)="getAAS(item.cxIdentification)">
              <mat-icon color="primary">pageview</mat-icon>
              View DT as JSON
            </button>

            <button mat-menu-item [matMenuTriggerFor]="menu">
              <mat-icon color="primary">add_circle</mat-icon>
              Add Aspect Model
            </button>
            <mat-menu #menu="matMenu">
              <button *ngIf="item.digitalTwinType == DigitalTwinType.PartInstance" mat-menu-item
                (click)="createAspectModel(item.id, 'batch/new')">Batch
              </button>
              <button *ngIf="item.digitalTwinType == DigitalTwinType.PartType" mat-menu-item
                (click)="createAspectModel(item.id, 'part-type-information/new')">Part Type Information
              </button>
            </mat-menu>

            <button mat-menu-item (click)="openConfirmationPopup(item)">
              <mat-icon color="warn">delete</mat-icon>
              Delete
            </button>
          </mat-menu>

          <ng-template #unregisterButton>
            <button mat-icon-button matTooltip="Unregister">
              <mat-icon class="icon-unregister">cloud_download</mat-icon>
            </button>
          </ng-template>
        </div>
      </div>
    </div>
  </div>
  <mat-paginator [pageSizeOptions]="[15, 20, 25]" (page)="pageChanged($event)"></mat-paginator>
</app-card-layout>
