<app-nav [breadcrumbList]="breadcrumb"></app-nav>

<div style="display: flex; flex-direction: column; align-items: center; margin-bottom: 40px;">
  <form [formGroup]="formGroup">
    <mat-stepper linear #stepper formArrayName="formArray">

      <mat-step formGroupName="0" [stepControl]="formArray.get([0])">
        <h4>Delivery</h4>
        <p>This configuration applies to delivery information that can be shared with direct customers.</p>
        <mat-form-field appearance="outline" class="width-48">
          <mat-label>Delivery</mat-label>
          <input matInput formControlName="useCase">
        </mat-form-field>
        <div class="m-t-20px">
          <button mat-raised-button color="primary" (click)="goForward(stepper)" type="button">Next
            >
          </button>
        </div>
      </mat-step>

      <!-- Step 0 -->
      <mat-step formGroupName="1" [stepControl]="formArray.get([1])">
        <h4>Operating Division</h4>
        <p>
          This configuration applies to the chosen operating division. Make sure that you are authorized for this
          operating division.
        </p>
        <mat-form-field appearance="outline" class="width-48" floatLabel="always">
          <mat-select formControlName="odName" placeholder="Operating Division"
                      (selectionChange)="filterODs($event.value)">
            <mat-option *ngFor="let item of odList" [value]="item">
              {{ item._id.od }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <div class="m-t-20px">
          <button mat-raised-button color="primary" (click)="goBack(stepper)" type="button" class="m-r-10px">
            < Previous
          </button>
          <button mat-raised-button color="primary" (click)="goForward(stepper)" type="button"> Next
            >
          </button>
        </div>
      </mat-step>

      <!-- Step 1 -->
      <mat-step formGroupName="2" [stepControl]="formArray.get([2])">
        <h4>Customers</h4>

        <p>
          Please enter here the selected customer ids with whom you want to share information. You can enter any
          customer id you want. The data is only shared if the customer is a sold to customer, gets a
          delivery of this product and is known in the network.
        </p>

        <mat-form-field appearance="outline" class="width-48" floatLabel="always">
          <mat-select formControlName="partnerName" placeholder="Partner List" (selectionChange)="selectPartner($event)">
            <mat-option *ngFor="let partnerNameSelect of partnerNameList" [value]="partnerNameSelect">
              {{ partnerNameSelect._id.partnerName }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <div class="m-t-20px">
          <button mat-raised-button color="primary" (click)="goBack(stepper);" type="button" class="m-r-10px">
            < Previous
          </button>
          <button mat-raised-button color="primary" (click)="goForward(stepper);" type="button">Next ></button>
        </div>
      </mat-step>

      <!-- Step 2 -->
      <mat-step formGroupName="3" [stepControl]="formArray.get([3])">
        <h4>Products</h4>
        <p>
          Please decide if you want to share information to products that are delivered into the network or if you want
          to select products. If you decide to select products please make sure that this list is always updated.
        </p>

        <mat-radio-group aria-label="Select an option" formControlName="selectAllProducts">
          <mat-radio-button [value]="true" class="width-48">All products
          </mat-radio-button>
          <br>
          <mat-radio-button [value]="false" class="width-48">Select products
          </mat-radio-button>
        </mat-radio-group>

        <div>
          <p>
            Please enter here the selected product numbers that you want to share. You can enter any product number you
            want. The data is only shared if the product is delivered to a customer in the network and if the product
            number is found on the delivery document.
          </p>

          <mat-form-field *ngIf="!selectAllProducts" appearance="outline" class="width-48" floatLabel="always">
            <mat-label>Material</mat-label>
            <mat-chip-grid #chipList aria-label="Material selection">
              <mat-chip-row *ngFor="let material of emptyMaterialList" (removed)="remove(material)">
                {{ material._id.materialName ? material._id.materialName : material._id.materialNumber }}
                <button matChipRemove [attr.aria-label]="'remove ' + (material._id.materialName ? material._id.materialName : material._id.materialNumber)">
                  <mat-icon>cancel</mat-icon>
                </button>
              </mat-chip-row>
            </mat-chip-grid>
            <input placeholder="New Material..." #materialInput [formControl]="materialNameControl"
                   [matChipInputFor]="chipList" [matAutocomplete]="auto"
                   [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                   (matChipInputTokenEnd)="addManual($event)"/>
            <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" (optionSelected)="addFromDropdown($event)">
              @for (option of filteredMaterials | async; track option) {
                <mat-option [value]="option">{{ option._id.materialName }}</mat-option>
              }
            </mat-autocomplete>
          </mat-form-field>

        </div>
        <div class="m-t-20px">
          <button mat-raised-button color="primary" (click)="goBack(stepper);" type="button" class="m-r-10px">
            < Previous
          </button>
          <button mat-raised-button color="primary" (click)="submit()">Create Shared Items</button>
        </div>
      </mat-step>
    </mat-stepper>
  </form>
</div>
