import {Injectable, OnDestroy} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router} from '@angular/router';
import {Observable, Subscription} from 'rxjs';
import {Group} from 'src/app/core/models/group';
import {AuthenticationService} from '../../authentication/authentication.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate, OnDestroy {
  subscription: Subscription;

  /** @ignore */
  constructor(
    private router: Router,
    private authenticationService: AuthenticationService
  ) {}

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  /** If the user is not logged in, it will redirect the user to /login page and pass the current URL pathname via the route state. */
  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (this.authenticationService.isAuthenticated()) {
      if (!this.authenticationService.isUserInAtLeastInOneOfGroups([
        Group.ADMIN, Group.DEVELOPER,
        Group.MP_OD1
      ])) {
        this.router.navigate(['not-authorized']);
        return false;
      }
      return true;
    } else {
      return false;
    }
  }
}
